@import "../less_inc/colour.less";

body{
    font-family: Arial, sans-serif;
    font-size: 13px;
}
@media (min-width: 980px) {
    body {
        width: 980px;
        margin-left: auto;
        margin-right: auto;
        
        
        p.footer{
            margin-top: 200px;
        }
    }
}
h1{
    border-top: @green solid;
    padding-top: 15px;
    font-size: 21px;
    margin-bottom: 15px;
}
p.footer{
    border-top: @green solid;
    margin-top: 100px;
    font-size: 10px;
    padding-top: 15px;
}

